$(function(){
  var $galleries = $(".gallery");
  $galleries.each(function(index, gallery) {
    var $gallery = $(gallery);
    $gallery.attr('id', 'gallery' + index);
    if ($gallery.unitegallery) {
      $gallery.unitegallery();
    }
  });
});
