$(function(){
  $('.hero-slide').slick({
    dots: true,
    fade: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false
  });

  // Room section at homepage
  $('.room-slide').slick({
    fade: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false
  });


  $('.room-img-slide').slick({
    fade: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false
  });

  $('.promotion-img-slide').slick({
    fade: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false
  });
});
