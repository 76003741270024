$(function(){
  setActiveLangLink();
  var enLink = location.href.replace('//th.', '//www.');
  var thLink = enLink.split('//www.').join('//th.');
  $('#en-link').attr('href', enLink);
  $('#th-link').attr('href', thLink);

  function setActiveLangLink() {
    if (location.href.indexOf('//th.') == -1) {
      $('#en-link').addClass('active');
    } else {
      $('#th-link').addClass('active');
    }
  }
});
