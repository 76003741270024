$(function(){
  var $mapContainer = $('.map-container');
  $('.map-container').click(function(){
    setMapClickable(true);
  }).mouseleave(function(){
    setMapClickable(false);
  });

  $('.map-container .map-enter-btn').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    setMapClickable(true);
  });
  $('.map-container .map-exit-btn').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    setMapClickable(false);
  });

  function setMapClickable(clickable) {
    if (clickable) {
      $mapContainer.addClass('clickable');
    } else {
      $mapContainer.removeClass('clickable');
    }
  }
});
