$(function(){
  $('#mobile .menu-btn').click(function(){
    var $parent = $(this).parents('#mobile');
    var isSelected = $parent.hasClass('selected')
    if (isSelected) {
      $parent.removeClass('selected');
    } else {
      $parent.addClass('selected');
    }
  });
});
