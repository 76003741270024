$(function(){
  var roomRatesBtnText = $($('.room-rates-btn')[0]).text();
  $('.room-rates-btn').click(function(e){
    e.preventDefault();
    var $ratesTable = $(this).parents('.rates-wrapper').find('table.rates');
    if ($ratesTable.is(':hidden')) {
      $ratesTable.removeClass('hide-mobile');
      $(this).text('HIDE ' + roomRatesBtnText);
    } else {
      $ratesTable.addClass('hide-mobile');
      $(this).text(roomRatesBtnText);
    }
  });

  showRoomDetails();
});

function showRoomDetails() {
  const $hotelCard = $('.hotel-rooms .card');
  $hotelCard.hover(function() {
    if (mobileMode($(this))) return;
    $(this).find('.desktop.room-details').css('display', 'block');
  });

  $hotelCard.mouseleave(function() {
    if (mobileMode($(this))) return;
    $(this).find('.desktop.room-details').css('display', 'none');
  });
  function mobileMode($this) {
    return $this.find('.mobile.room-details').is(':visible');
  }
}
