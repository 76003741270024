$(function(){
  $menuContainer = $('#facilities .menu-container');
  showHideMenu();
  $(document).scroll(function() {
    showHideMenu();
  });
  function showHideMenu() {
    var scrollTop = $(document).scrollTop();
    if (scrollTop > 800) {
      $menuContainer.show();
    } else {
      $menuContainer.hide();
    }
  }
});
